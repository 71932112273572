<template>
	<div class="category-list-component-select">
		<el-input @input="handleSearch" size="mini" :clearable="true" :placeholder="searchPlaceholder"
			v-model="categSearch"></el-input>
		<ul :class="reactiveClass" class="category-access-list">
			<li :class="populateClass(categ)" @click="selectCateg(categ)" v-for="(categ, index) in categList"
				:key="index">
				<span @click="requestCategory(categ)">
					<svg-icon :class="getIconClass(categ, true)" :icon-class="getIconClass(categ)" />
				</span>
				<span class="categName">{{categ.categories_name}}
					<i v-if="hasChildren(categ)" class="el-icon-arrow-right"></i>
				</span>
			</li>
		</ul>
	</div>
</template>

<script>
	export default {
		props: ['catLevel', 'catList', 'selected'],
		data() {
			return {
				categSearch: '',
				cList: this.catList
			};
		},
		methods: {
			handleSearch({
				type,
				target
			}) {
				if (this.categSearch === '') {
					this.cList = this.catList;
					return false;
				}
				this.cList = this.catList.filter((cat) => {
					return cat.categories_name.toLowerCase().includes(this.categSearch.toLowerCase());
				});
			},
			hasAccess(categ) {
				return (typeof categ.hasAccess !== 'undefined' && categ.hasAccess === 2);
			},
			selectCateg(categ) {
				this.$emit('category-selected', {
					categObj: categ,
					nextLevel: this.categoryLevel + 1
				});
				this.$emit('valid-selection', (this.hasAccess(categ) ? categ : {
					categories_id: 0
				}));
			},
			populateClass(categ) {
				return {
					'hvr-fade': typeof categ.children !== 'undefined',
					'selected': this.selectedReactive.includes(categ.categories_id)
				};
			},
			getIconClass(categ, colorOnly = false) {
				if (colorOnly) {
					switch (true) {
						case (typeof categ.isInaccessible !== 'undefined' && parseInt(categ.isInaccessible) === 1 && (typeof categ.hasAccess === 'undefined' || categ.hasAccess == 0)):
							return 'red';

						case (typeof categ.hasAccess !== 'undefined' && categ.hasAccess === 1):
							return 'warning';

						case (typeof categ.hasAccess !== 'undefined' && categ.hasAccess === 2):
							return 'green';

						case (typeof categ.accessRequest !== 'undefined' && parseInt(categ.accessRequest) === -1):
							return 'danger';

						case (typeof categ.accessRequest !== 'undefined' && parseInt(categ.accessRequest) > 0):
							return 'warning';

						default:
							return 'blue';
					}
				} else {
					switch (true) {
						case (typeof categ.isInaccessible !== 'undefined' && parseInt(categ.isInaccessible) === 1 && (typeof categ.hasAccess === 'undefined' || categ.hasAccess == 0)):
							return 'cancel';

						case (typeof categ.hasAccess !== 'undefined' && categ.hasAccess === 1):
							return 'file';

						case (typeof categ.hasAccess !== 'undefined' && categ.hasAccess === 2):
							return 'checkmark';

						case (typeof categ.accessRequest !== 'undefined' && parseInt(categ.accessRequest) === -1):
							return 'minus';

						case (typeof categ.accessRequest !== 'undefined' && parseInt(categ.accessRequest) > 0):
							return 'hourglass';

						default:
							return 'unlock';
					}
				}
			},
			hasChildren(categ) {
				return typeof categ.children !== 'undefined';
			},
			canRequestAccess(categ) {
				return typeof categ.isInaccessible !== 'undefined' && parseInt(categ.isInaccessible) === 0 && categ
					.accessRequest === 0 && typeof categ.hasAccess !==
					'undefined' && parseInt(categ.hasAccess) === 0;
			},
			requestCategory(categ) {
				if (!this.canRequestAccess(categ)) return false;
				this.$emit('request-category-access', categ);
			}
		},
		computed: {
			categList() {
				return this.cList;
			},
			categoryLevel() {
				return this.catLevel;
			},
			reactiveClass() {
				return [`list-${this.categoryLevel}`];
			},
			selectedReactive() {
				return this.selected || [];
			},
			searchPlaceholder() {
				switch (parseInt(this.catLevel)) {
					case 0:
						return 'Supra-Categorie';

					case 1:
						return 'Categorie';

					case 2:
						return 'Sub-Categorie';

					default:
						return 'Categorie';
				}
			}
		},
		watch: {
			catList() {
				this.cList = this.catList;
			}
		},
		created() {

		},
		destroyed() {},
	};
</script>
<style rel="stylesheet/scss" lang="scss">
	.category-list-component-select {
		padding-right: 10px;

		.hvr-fade {
			-webkit-transform: perspective(1px) translateZ(0);
			transform: perspective(1px) translateZ(0);
			box-shadow: 0 0 1px rgba(0, 0, 0, 0);
			overflow: hidden;
			-webkit-transition-duration: 0.3s;
			transition-duration: 0.3s;
			-webkit-transition-property: color, background-color;
			transition-property: color, background-color;
		}

		.hvr-fade:hover,
		.hvr-fade:focus,
		.hvr-fade:active {
			background-color: #f5f5f5;
		}

		.category-access-list {
			list-style: none;
			padding: 0;
			margin: 0;
			height: 200px;
			overflow-y: auto;
			font-size: 14px;
			border: 1px solid #dcdfe6;

			li {
				cursor: pointer;
			}

			li {
				padding: 2px 0;
			}

			li .svg-icon {
				margin: 0 5px;
			}

			li.selected {
				background-color: #efefef;
			}

			i.el-icon-arrow-right {
				float: right;
				padding-right: 5px;
			}

			.svg-icon.red {
				color: #909399;
			}

			.svg-icon.green {
				color: #67C23A;
			}

			.svg-icon.blue {
				color: #409EFF;
			}

			.svg-icon.warning {
				color: #E6A23C;
			}

			.svg-icon.danger {
				color: #f56c6c;
			}
		}
	}
</style>